<template>
  <div class="editor-wrap" :style="styleObject">
    <editor
      ref="editor"
      v-model="innerValue"
      :theme="$vuetify.theme.dark ? 'dracula' : 'xcode'"
      :options="options"
      lang="json"
      :width="width"
      :height="height"
      @input="handleInput"
      @init="editorInit"
    />
    <v-btn icon class="editor-wrap__expand" @click="expandCode">
      <v-icon v-if="!isExpanded">mdi-arrow-expand</v-icon>
      <v-icon v-else>mdi-arrow-collapse</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api';

export default defineComponent({
  props: {
    code: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    editor: require('vue2-ace-editor')
  },
  name: 'CodeEditor',
  setup(props, { emit }) {
    const innerValue = ref(null);
    let editorInstance = null;

    const state = reactive({
      width: '100%',
      height: 100,
      isExpanded: false,
      options: {},
      styleObject: {
        position: 'relative',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 1
      }
    });

    const editorInit = editor => {
      editorInstance = editor;
      require('brace/mode/json');
      require('brace/mode/javascript');
      require('brace/theme/xcode');
      require('brace/theme/dracula');
      require('brace/ext/searchbox');
    };

    const expandCode = () => {
      if (state.isExpanded) {
        state.height = 100;
        state.styleObject.position = 'relative';
        state.styleObject.zIndex = 1;
        state.isExpanded = false;
      } else {
        state.height = '100vh';
        state.width = '100vw';
        state.styleObject.position = 'fixed';
        state.styleObject.zIndex = 9;
        state.isExpanded = true;
      }
    };

    const handleInput = () => {
      let value = editorInstance.getValue().trim();
      emit('input', value || null);
    };

    onMounted(() => {
      if (props.code) {
        innerValue.value = JSON.stringify(JSON.parse(props.code), null, 2);
      }

      if (props.isDisabled) {
        editorInstance.setReadOnly(true);
      }
    });

    return {
      ...toRefs(state),
      editorInit,
      innerValue,
      expandCode,
      handleInput
    };
  }
});
</script>

<style lang="scss">
.editor-wrap {
  position: relative;
  overflow: hidden;

  .ace_editor {
    font-size: 14px;
  }

  &__expand {
    z-index: 10;
    position: absolute;
    top: 5px;
    right: 15px;
  }
}
</style>
